import { v4 } from "uuid";
import { Hospital } from "./hospital";
import { Priority } from "./types";
import * as _ from 'lodash';

// You shouldn't need to modify this class
export class Order {
  id: string;
  shortId: string;
  time: number;
  hospital: Hospital;
  priority: Priority;

  constructor(time: number, hospital: Hospital, priority: Priority) {
    this.time = time;
    this.hospital = hospital;
    this.priority = priority;
    this.id = v4();
    this.shortId = _.toUpper(this.id.substring(0, 5));
  }

  /**
   * Reads and processes a CSV file object that conforms to the orders.csv schema defined in README.md.
   * Ok to assume the orders are sorted.
   * @param {string} file CSV file as a string to read into a dict.
   * @param {Record<string, Hospital>} hospitals mapping of hospital name to Hospital objects
   * @returns {Order[]} List of Order objects.
   */
  static loadFromCsv(
    file: string,
    hospitals: Record<string, Hospital>
  ): Order[] {
    const lines = file.split("\n");
    const orders: Order[] = lines.map((line) => {
      const [time, hospitalName, priority] = line
        .split(",")
        .map((value) => value.trim());
      return new Order(
        parseInt(time, 10),
        hospitals[hospitalName],
        priority as Priority
      );
    });
    return orders;
  }
}
