import * as React from 'react';
import { SidebarWidget } from '../widget/sidebar-widget';
import { AppContext } from '../../../context';
import './status.css';

export const Status = () => {
  const { flights } = React.useContext(AppContext);
  return (
    <SidebarWidget title='Status'>
      <div className='sidebar-status'>
        <div className='sidebar-status-entry'>
          <div className='sidebar-status-entry-title'>
            Available zips:
          </div>
          <div className='sidebar-status-entry-value'>
            {10 - flights.length}
          </div>
        </div>
      </div>
    </SidebarWidget>
  );
}
