import { Flight } from "../simulation/models/flight";
import { Waypoint } from "../simulation/models/route";
import * as _ from 'lodash';

export interface InferProgressResult {
  waypoint: Waypoint;
  previousWaypoint: Waypoint;
  waypointIndex: number;
  fractionalProgressInWaypoint: number;
}

const getActiveToWaypoint = (flight: Flight, currentTime: number): [ Waypoint, number, Waypoint ] | null => {
  for (let i = 1; i < flight.route.waypoints.length; i++) {
    const previous = flight.route.waypoints[i - 1];
    const current = flight.route.waypoints[i];
    if (currentTime > previous.time && currentTime < current.time) {
      return [ current, i, previous ];
    }
  }
  return null;
}

export const inferProgress = (flight: Flight, currentTime: number): InferProgressResult | null => {
  const result = getActiveToWaypoint(flight, currentTime);
  if (!result) {
    return null;
  }
  const [ waypoint, waypointIndex, previousWaypoint ] = result;

  const totalWaypointDuration = waypoint.time - previousWaypoint.time;
  const timeOffsetWithin = currentTime - previousWaypoint.time;
  const fractionalProgressInWaypoint = timeOffsetWithin / totalWaypointDuration;

  return {
    waypoint,
    waypointIndex,
    previousWaypoint,
    fractionalProgressInWaypoint
  };
};

export const getInFlightPosition = (flight: Flight, currentTime: number): [number, number] | null => {
  const progress = inferProgress(flight, currentTime);
  if (!progress) {
    return null;
  }

  const startX = progress.previousWaypoint.location.eastM;
  const startY = progress.previousWaypoint.location.northM;
  const endX = progress.waypoint.location.eastM;
  const endY = progress.waypoint.location.northM;

  const diffX = endX - startX;
  const diffY = endY - startY;

  const posX = startX + (diffX * progress.fractionalProgressInWaypoint);
  const posY = startY + (diffY * progress.fractionalProgressInWaypoint);

  return [
    posX,
    posY
  ];
}
