import * as React from 'react';
import { AppContext } from '../../context';
import { Hospital } from '../../simulation/models/hospital';
import './hospital-marker.css';
import { getHospitalPosition } from '../../display/coords';
import classNames from 'classnames';

export interface HospitalMarkerProps {
  hospital: Hospital;
}

export const HospitalMarker = ({ hospital }: HospitalMarkerProps) => {
  const { viewport, activeHospitals } = React.useContext(AppContext);
  if (!viewport) {
    return null;
  }

  const pos = getHospitalPosition(viewport, hospital);
  const isActiveResupply = activeHospitals.resupply.has(hospital.name);
  const isActiveEmergency = activeHospitals.emergency.has(hospital.name);

  const className = classNames('zip-hospital', {
    'zip-hospital-active-resupply': isActiveResupply,
    'zip-hospital-active-emergency': isActiveEmergency,
  });

  return (
    <circle className={className} cx={pos[0]} cy={pos[1]} r={5}></circle>
  );
};
