import { Flight } from "./flight";
import { Order } from "./order";
import * as _ from 'lodash';
import { createSimpleRoute } from "./route";

export interface PrioritySchedulerArgs {
  maxPerBin: number;
  zipSpeedMps: number;
  currentTime: number;
  availableCapacity: number;
}

export interface Bin {
  orders: Order[];
}

export class PriorityScheduler {
  private fullBins: Bin[];
  private partialBins: { [destination: string]: Bin };

  private maxPerBin: number;
  private zipSpeedMps: number;
  private currentTime: number;
  private availableCapacity: number;

  public constructor({ maxPerBin, zipSpeedMps, currentTime, availableCapacity }: PrioritySchedulerArgs) {
    this.fullBins = [];
    this.partialBins = {};

    this.maxPerBin = maxPerBin;
    this.zipSpeedMps = zipSpeedMps;
    this.currentTime = currentTime;
    this.availableCapacity = availableCapacity;
  }

  private ordersToFlight(currentTime: number, orders: Order[]): Flight {
    const route = createSimpleRoute(orders, this.zipSpeedMps);
    return new Flight(currentTime, route, 'Emergency');
  }

  fillFromQueue(queue: Order[], allowCreate: boolean) {
    const remove = new Set<string>();
    for (let i = 0; i < queue.length; i++) {
      const order = queue[i];
      if (this.addToBin(order, allowCreate)) {
        remove.add(order.id);
      }
    }
    _.remove(queue, (order) => remove.has(order.id));
  }

  addToBin(order: Order, allowCreate: boolean): boolean {
    const partialBin = this.partialBins[order.hospital.name];

    if (partialBin && partialBin.orders.length === this.maxPerBin - 1) {
      // Bin is full with orders; add it to the list of full bins and clear the map
      partialBin.orders.push(order);
      this.fullBins.push(partialBin);
      delete this.partialBins[order.hospital.name];
      return true;
    } else if (partialBin && partialBin.orders.length < this.maxPerBin - 1 && partialBin.orders.length > 0) {
      // Bin receives an additional order
      this.partialBins[order.hospital.name].orders.push(order);
      return true;
    } else if (allowCreate && this.availableCapacity > 0) {
      // Bin gets its first order
      this.partialBins[order.hospital.name] = {
        orders: [order]
      };
      this.availableCapacity -= 1;
      return true;
    }
    return false;
  }

  getFlights() {
    const allBins: Bin[] = _.reduce(_.values(this.partialBins), (existing, orders) => [ ...existing, orders ], this.fullBins);
    return _.map(allBins, (bin) => this.ordersToFlight(this.currentTime, bin.orders));
  }
}
