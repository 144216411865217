import { useEffect, useRef, useState } from 'react';
import { Runner, createRunner } from './simulation/runner';
import { Clock } from './components/clock/clock';
import { AppContext, SimulationState } from './context';
import { Hospitals } from './components/hospitals/hospitals';
import { Viewport, createViewport } from './display/viewport';
import { Nest } from './components/nest/nest';
import { Flights } from './components/flights/flights';
import { getActiveHospitalSets } from './display/active';
import { Sidebar } from './components/sidebar/sidebar';
import { Hospital } from './simulation/models/hospital';

const wait = (time: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, time);
  });
};

export interface RunnerState {
  runner: Runner;
  viewport: Viewport;
  hospitals: Hospital[];
}

function App() {
  // Component state
  const [ runnerState, setRunner ] = useState<RunnerState>();
  const [ sam, setSam ] = useState<number>(0);
  const [ speedTimesOneThousand, setSpeedTimesOneThousand ] = useState(1);
  const runnerRef = useRef(runnerState);
  const samRef = useRef(sam);

  // State passed down to lower components in the app, showing current simulation data
  const [ state, setState ] = useState<Partial<SimulationState>>({
    time: 0,
    hospitals: [],
    flights: [],
    activeHospitals: { resupply: new Set(), emergency: new Set() },
    orderQueue: []
  });

  const initializeRunner = () => {
    createRunner().then((runner) => {
      const hospitals = runner.getHospitals();
      const viewport = createViewport(hospitals);
      setRunner({
        runner,
        hospitals,
        viewport,
      });

      const firstTime = runner.getFirstOrderTime();
      setSam(firstTime);
    })
  }

  // Callback to update playback speed
  const updateSpeedTimesOneThousand = (num: number) => {
    setSpeedTimesOneThousand(num);
  }

  // Callback function to reset the simulation to starting time
  const resetSim = (num: number) => {
    if (!runnerState) {
      return;
    }
    initializeRunner();
  }

  // This control loop runs the simulation
  const secondsPerDay = 24 * 60 * 60;
  const controlLoop = () => {
    if (!runnerRef.current) {
      return;
    }
    const { viewport, hospitals, runner } = runnerRef.current;
    const finalIteration = samRef.current + (10 * speedTimesOneThousand);

    // Advance simulation for specified number of iterations
    for (
      let timeNow = samRef.current;
      timeNow < secondsPerDay && timeNow < finalIteration;
      timeNow += 1
    ) {
      runner.stepSimulation(timeNow);
    }

    const flights = runner.flights;
    const activeHospitals = getActiveHospitalSets(flights);
    const orderQueue = runner.getOrderQueue();

    setState({
      time: finalIteration,
      viewport,
      hospitals,
      flights,
      activeHospitals,
      orderQueue
    });
    
    setSam(finalIteration);
  };

  // This effect initializes the runner
  useEffect(() => {
    initializeRunner();
  }, []);

  // This effect boots up an interval control loop whenever its configuration is changed
  useEffect(() => {
    let intervalId: any;
    if (runnerState) {
      intervalId = setInterval(controlLoop, 1000 / 30);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [runnerState, speedTimesOneThousand]);

  // References are used to pass state data to the interval
  useEffect(() => {
    samRef.current = sam;
  }, [sam]);
  useEffect(() => {
    runnerRef.current = runnerState;
  }, [runnerState]);

  const fullContext = {
    ...state,
    speedTimesOneThousand,
    updateSpeedTimesOneThousand,
    resetSim
  } as SimulationState;

  const showDetails = () => {
    window.scrollTo({top: 500, behavior: 'smooth'});
  }

  return (
    <AppContext.Provider value={fullContext}>
      <Clock />
      <div className='app-wrap'>
        <div className='app-svg-wrap'>
          <svg className='app-svg'>
            <Hospitals />
            <Flights />
            <Nest />
          </svg>
        </div>
        <div className='mobile-tab' onClick={showDetails}>
          More Details
        </div>
        <div className='app-sidebar-wrap'>
          <Sidebar />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
