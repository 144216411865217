import { distance2D } from "./geometry";
import { GraphPath, createNest } from "./graph";
import { Hospital } from "./hospital";
import { Order } from "./order";
import * as _ from 'lodash';

export const getRouteSchedule = (path: Route) => {
  return [0,];
}

export interface Waypoint {
  location: Hospital;
  time: number;
}

export interface Route {
  orders: Order[];
  waypoints: Waypoint[];
  flightTime: number;
}

export const createSimpleRoute = (orders: Order[], speed: number): Route => {
  const order = orders[0];
  const flightDistance = distance2D(0, 0, order.hospital.eastM, order.hospital.northM) * 2;
  const flightTime = flightDistance / speed;
  const nest = createNest();

  return {
    orders: [order],
    waypoints: [
      {
        location: nest,
        time: 0
      },
      {
        location: order.hospital,
        time: flightTime / 2
      },
      {
        location: nest,
        time: flightTime
      },
    ],
    flightTime
  };
};

export const createAdvancedRoute = (orders: Order[], graphPath: GraphPath, speed: number): Route => {
  const flightTime = graphPath.totalDistance / speed;

  const nest = createNest();
  const stops = [  
    nest,
    ..._.uniqBy(_.map(orders, order => order.hospital), hos => hos.name),
    nest,
  ];
  
  const waypoints = [];
  waypoints.push({
    location: nest,
    time: 0
  });

  let cumulativeTime = 0;
  for (let i = 1; i < stops.length; i++) {
    const flightDistance = distance2D(stops[i - 1].eastM, stops[i - 1].northM, stops[i].eastM, stops[i].northM);
    const flightTime = flightDistance / speed;
    cumulativeTime += flightTime;
    waypoints.push({
      location: stops[i],
      time: cumulativeTime
    });
  }

  return {
    orders,
    waypoints,
    flightTime
  };
}
