import * as React from 'react';
import { AppContext } from '../../context';
import * as _ from 'lodash';
import './nest.css';
import { getNestPosition } from '../../display/coords';

export const Nest = () => {
  const { viewport } = React.useContext(AppContext);
  if (!viewport) {
    return null;
  }

  const pos = getNestPosition(viewport);

  return (
    <circle className='zip-nest' cx={pos[0]} cy={pos[1]} r={5}></circle>
  );
};
