import { distance2D } from "./geometry";
import { GraphPath, createHospitalGraph, createNest, findShortestPath } from "./graph";
import { Order } from "./order";
import { Location } from "./location";
import * as _ from 'lodash';
import { Flight } from "./flight";
import { createAdvancedRoute, createSimpleRoute } from "./route";

export interface StandardSchedulerArgs {
  maxPerBin: number;
  zipSpeedMps: number;
  currentTime: number;
  availableCapacity: number;
  maxDistance: number;
  eagerSendCapacity: number;
}

export interface StandardScheduleBin {
  orders: Order[];
  path: GraphPath | null;
}

export class StandardScheduler {
  private fullBins: StandardScheduleBin[];

  private maxPerBin: number;
  private zipSpeedMps: number;
  private currentTime: number;
  private availableCapacity: number;
  private eagerSendCapacity: number;
  private maxDistance: number;
  private nest: Location;

  public constructor({ maxPerBin, zipSpeedMps, currentTime, availableCapacity, maxDistance, eagerSendCapacity }: StandardSchedulerArgs) {
    this.maxPerBin = maxPerBin;
    this.zipSpeedMps = zipSpeedMps;
    this.currentTime = currentTime;
    this.availableCapacity = availableCapacity;
    this.maxDistance = maxDistance;
    this.eagerSendCapacity = eagerSendCapacity;
    this.fullBins = [];
    this.nest = createNest();
  }

  private getPriorityByHospital(orders: Order[]) {
    // Collect information needed for below operations
    const hospitals = _.map(orders, order => order.hospital);
    const hospitalsUnique = _.uniqBy(hospitals, hos => hos.name);
    const hospitalsByName = _.keyBy(hospitalsUnique, hos => hos.name);
    const hospitalNames = _.map(hospitalsUnique, hos => hos.name)

    // Compute frequency and distance
    const frequencyCounts = _.countBy(hospitals, hos => hos.name);
    const distanceByHospital = _.mapValues(hospitalsByName, hos => distance2D(0, 0, hos.eastM, hos.northM));

    // Combine into one object for sorting
    const augmentedHospitals = _.zipObject(
      hospitalNames,
      _.map(hospitalNames, (name) => ({
        ...hospitalsByName[name],
        distance: distanceByHospital[name],
        frequency: frequencyCounts[name]
      }))
    );

    // Sort by desired properties
    const sorted = _.orderBy(augmentedHospitals, ['frequency', 'distance'], ['desc', 'asc']);

    // Use indexes to identify priority order; this ensures that two hospitals with identical rankings have their
    // orders grouped separately.
    const priorityOrderAsObject = _.zipObject(
      _.map(sorted, hos => hos.name),
      _.map(sorted, (hos, idx) => idx),
    );
    return priorityOrderAsObject;
  }

  fillFromQueue(queue: Order[]) {
    // Work from a copy of the queue, because we might not actually launch all the hypothetical flights
    // that we are planning
    const queueCopy = _.cloneDeep(queue);

    // Sort orders by hospital priority score. This accomplishes three things. First, it places packages that are
    // going to the same place beside each other in the queue, helping to ensure those orders end up on the same
    // zips. Second, it places hospitals that are receiving the most packages at the top of the list, to maximize
    // grouping by same-location. Third, closeness is used as the tiebreaker, since trips to closer hospitals are
    // more likely to be able to visit farther ones at the same time. This tiebreaker will be important for data
    // sets with only 1 package going to each of, say, 20 locations. 
    const priorityByHospital = this.getPriorityByHospital(queueCopy);

    const sortedQueue = _.sortBy(queueCopy, order => priorityByHospital[order.hospital.name]);

    // This is the bin (zip) that we are currently filling
    let workingBin: StandardScheduleBin = {
      orders: [],
      path: null
    }

    const newFullBins: StandardScheduleBin[] = [];

    // Max iteration count as a precaution against infinite loops; no infinite loops
    // were observed in testing this code, but the control logic here is complex enough to
    // justify it. 
    let maxIterationCount = sortedQueue.length * 2;

    // Go through each item in the queue until all are slotted into a flight
    for (let i = 0; sortedQueue.length > 0 && i < maxIterationCount; i++) {
      const toAdd = sortedQueue.pop();
      if (!toAdd) {
        continue;
      }

      // Validate travel distance for this hypothetical package manifest
      const locationsVisited = _.uniqBy(_.map([ ...workingBin.orders, toAdd ], order => order.hospital), hos => hos.name);
      const currentGraph = createHospitalGraph(this.nest, locationsVisited);
      const shortestPath = findShortestPath(currentGraph, this.nest, this.maxDistance);

      // Check manifesting requirements (max packages & max distance)
      if (workingBin.orders.length + 1 > this.maxPerBin || !shortestPath) {
        // If this flight can't fit the current package, push it back to the queue ...
        sortedQueue.push(toAdd);

        // ... and add the working bin to the list of full flights
        newFullBins.push(workingBin);

        // Reduce available capacity
        this.availableCapacity -= 1;

        // ... and create a new working flight
        workingBin = {
          orders: [toAdd],
          path: shortestPath
        }

        // ... and check to see if we've planned all we have capacity to launch
        if (this.availableCapacity <= 0) {
          break;
        }

        // ... and skip the step that would normally add this to the working bin, so that we can
        // revalidate and get a new route on the next go-round
        continue;
      }

      workingBin.path = shortestPath;
      workingBin.orders.push(toAdd);
    }

    // Note, we are intentionally discarding the working bin here, because we don't have any reason to think it is
    // full. Another package may come in one minute later that could have gone on it. 

    // EXCEPT, working bin is sent if there is ample capacity, to help clear out orders at the end of the day
    if (workingBin && workingBin.orders.length > 0 && this.availableCapacity >= this.eagerSendCapacity) {
      newFullBins.push(workingBin);
    }

    // Remove orders from the original queue that were scheduled
    const allToRemove = new Set(_.flatMap(newFullBins, bin => _.map(bin.orders, order => order.id)));
    _.remove(queue, (order) => allToRemove.has(order.id));

    // Save the bins that have been added during this operation
    this.fullBins = [ ...this.fullBins, ...newFullBins ];
  }

  private binToFlight(currentTime: number, bin: StandardScheduleBin): Flight | null {
    if (bin.orders.length === 0) {
      return null;
    }
    if (bin.orders.length > 1 && !bin.path) {
      return null;
    }

    const route = bin.orders.length === 0
      ? createSimpleRoute(bin.orders, this.zipSpeedMps)
      : createAdvancedRoute(bin.orders, bin.path as GraphPath, this.zipSpeedMps);

    return new Flight(currentTime, route, 'Resupply');
  }

  getReadyFlights(): Flight[] {
    const flights = _.map(this.fullBins, (bin) => this.binToFlight(this.currentTime, bin));
    return _.filter(flights, v => Boolean(v)) as Flight[];
  }
}
