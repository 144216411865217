import * as React from 'react';
import './sidebar-widget.css';

export interface SidebarWidgetProps {
  title: string;
  children: React.ReactElement;
}

export const SidebarWidget = ({ title, children }: SidebarWidgetProps) => {
  return (
    <div className='sidebar-widget'>
      <div className='sidebar-widget-title'>
        {title}
      </div>
      <div className='sidebar-widget-content'>
        {children}
      </div>
    </div>
  );
}
