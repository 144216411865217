import _ from "lodash";
import { Flight } from "../simulation/models/flight";
import { Hospital } from "../simulation/models/hospital";
import { Viewport, getBaselineScale } from "./viewport";
import { MARGIN_LEFT, MARGIN_TOP } from "./margins";
import { getInFlightPosition } from "./dead-reckoning";

export const getProjectedCoordinates = (viewport: Viewport, x: number, y: number): [number, number] => {
  const { innerWidth, innerHeight } = window;
  const zeroX = innerWidth / 2;
  const zeroY = innerHeight / 2;

  const scale = getBaselineScale(viewport);
  const relativeX = x * scale;
  const relativeY = y * scale;

  const absoluteX = MARGIN_LEFT + zeroX + relativeX;
  const absoluteY = MARGIN_TOP + zeroY + relativeY;

  return [
    absoluteX,
    absoluteY
  ];
}

export const getHospitalPosition = (viewport: Viewport, hos: Hospital): [number, number] => {
  return getProjectedCoordinates(viewport, hos.eastM, hos.northM);
}

export const getNestPosition = (viewport: Viewport): [number, number] => {
  const { innerWidth, innerHeight } = window;

  const zeroX = innerWidth / 2;
  const zeroY = innerHeight / 2;

  return [
    MARGIN_LEFT + zeroX,
    MARGIN_TOP + zeroY
  ];
}

export interface FlightPosition {
  origin: [number, number];
  destination: [number, number];
  current: [number, number] | undefined;
  isDuplicate: boolean;
}

export const getFlightPositions = (viewport: Viewport, flight: Flight, currentTime: number): FlightPosition[] => {
  const coords: [number, number][] = _.map(flight.route.waypoints, wp => [wp.location.eastM, wp.location.northM]) as [number, number][];
  const createdPaths = new Set<string>();

  const positions = [];
  for (let i = 0; i < coords.length - 1; i++) {
    const currentCoords = getInFlightPosition(flight, currentTime - flight.launchTime);
    const origin = getProjectedCoordinates(viewport, coords[i][0], coords[i][1]);
    const destination = getProjectedCoordinates(viewport, coords[i + 1][0], coords[i + 1][1]);
    const current = currentCoords ? getProjectedCoordinates(viewport, currentCoords[0], currentCoords[1]) : undefined;

    // Detect duplicate paths to avoid double-rendering
    const pathKey = `${origin[0]}-${origin[1]}->${destination[0]}-${destination[1]}`;
    const pathKeyReverse = `${destination[0]}-${destination[1]}->${origin[0]}-${origin[1]}`;
    const isDuplicate = createdPaths.has(pathKey) || createdPaths.has(pathKeyReverse);
    createdPaths.add(pathKey);
    createdPaths.add(pathKeyReverse);

    positions.push({
      origin,
      destination,
      current,
      isDuplicate
    });
  }

  return positions;
};
