import * as React from 'react';
import { SidebarWidget } from '../widget/sidebar-widget';
import { AppContext } from '../../../context';
import * as _ from 'lodash';
import './queue.css';

const TITLE = 'Queued Orders';

export const Queue = () => {
  const { orderQueue } = React.useContext(AppContext);
  const sortedQueue = _.sortBy(_.cloneDeep(orderQueue), order => order.time);

  if (sortedQueue.length === 0) {
    return (
      <SidebarWidget title={TITLE}>
        <div className='sidebar-empty-row'>
          No items to display
        </div>
      </SidebarWidget>
    );
  }

  return (
    <SidebarWidget title={TITLE}>
      <div className='sidebar-order-list'>
        {sortedQueue.map(order => (
          <div className='sidebar-order' key={order.id}>
            <div className='sidebar-order-number'>
              <strong>Order #{order.shortId}</strong>
            </div>
            <div className='sidebar-order-priority'>
              {order.priority}
            </div>
            <div className='sidebar-order-to'>
              To: {order.hospital.name}
            </div>
          </div>
        ))}
      </div>
    </SidebarWidget>
  );
}
