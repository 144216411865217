import { Flight } from "../simulation/models/flight";
import * as _ from 'lodash';
import { Priority } from "../simulation/models/types";

const getPriorityWaypoints = (flights: Flight[], priority: Priority) => {
  return _.flatMap(_.filter(flights, flight => flight.priority === priority), flight => flight.route.waypoints);
};

export const getActiveHospitalSets = (flights: Flight[]): { resupply: Set<string>, emergency: Set<string> } => {
  const emergencyWaypoints = getPriorityWaypoints(flights, 'Emergency');
  const resupplyWaypoints = getPriorityWaypoints(flights, 'Resupply');
  return {
    emergency: new Set(_.uniq(_.map(emergencyWaypoints, wp => wp.location.name))),
    resupply: new Set(_.uniq(_.map(resupplyWaypoints, wp => wp.location.name))),
  }
}
