import * as React from 'react';
import { AppContext } from '../../context';
import * as _ from 'lodash';
import { FlightPath } from './flight-path';
import { FlightPosition, getFlightPositions } from '../../display/coords';
import { Flight } from '../../simulation/models/flight';

const makeKey = ({ flight, path }: { flight: Flight, path: FlightPosition }) => {
  return `${flight.id}-${path.origin[0]}-${path.origin[1]}-${path.destination[0]}-${path.destination[1]}`
}

export const Flights = () => {
  const { flights, viewport, time } = React.useContext(AppContext);
  if (!viewport) {
    return null;
  }

  // ({ flight, path: getFlightPositions(viewport, flight, time) })
  const allDetails = _.flatMap(flights, flight => _.flatMap(getFlightPositions(viewport, flight, time), path => ({ flight, path })));

  return (
    <>
      {_.map(allDetails, details => <FlightPath key={makeKey(details)} {...details} />)}
    </>
  );
};
