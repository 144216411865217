import { createContext } from "react";
import { Viewport } from "./display/viewport";
import { Hospital } from "./simulation/models/hospital";
import { Flight } from "./simulation/models/flight";
import { Order } from "./simulation/models/order";

export interface SimulationState {
  time: number;
  viewport?: Viewport;
  hospitals: Hospital[];
  flights: Flight[];
  activeHospitals: { resupply: Set<string>; emergency: Set<string>; };
  orderQueue: Order[];
  speedTimesOneThousand: number;
  updateSpeedTimesOneThousand: (num: number) => void;
  resetSim: () => void;
}

export const AppContext = createContext<SimulationState>({
  time: 0,
  hospitals: [],
  flights: [],
  activeHospitals: { resupply: new Set(), emergency: new Set() },
  orderQueue: [],
  speedTimesOneThousand: 1,
  updateSpeedTimesOneThousand: () => {},
  resetSim: () => {}
});
