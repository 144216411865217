
// Each Nest has this many Zips
export const NUM_ZIPS = 10;

// Each Zip can carry between 1 and this many packages per flight
// Note: a Zip can deliver more than 1 package per stop
export const MAX_PACKAGES_PER_ZIP = 3;

// Zips fly a constant groundspeed (m/s)
export const ZIP_SPEED_MPS = 30;

// Zips can fly a total roundtrip distance (m)
export const ZIP_MAX_CUMULATIVE_RANGE_M = 160 * 1000; // 160 km -> meters
