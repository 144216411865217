import * as React from 'react';
import { AppContext } from '../../context';
import './flight-path.css';
import { FlightPosition } from '../../display/coords';
import { Flight } from '../../simulation/models/flight';
import classNames from 'classnames';

export interface FlightPathProps {
  path: FlightPosition;
  flight: Flight;
}

export const FlightPath = ({ path, flight }: FlightPathProps) => {
  const { viewport } = React.useContext(AppContext);
  if (!viewport) {
    return null;
  }

  const { origin, destination, current, isDuplicate } = path;
  const { priority } = flight;

  const lineClasses = classNames('flight-path-line', {
    'flight-path-line-emergency': priority === 'Emergency',
    'flight-path-line-resupply': priority === 'Resupply',
  });
  const circleClasses = classNames('flight-path-zip', {
    'flight-path-zip-emergency': priority === 'Emergency',
    'flight-path-zip-resupply': priority === 'Resupply',
  });

  return (
    <>
      {!isDuplicate && <line className={lineClasses} x1={origin[0]} y1={origin[1]} x2={destination[0]} y2={destination[1]}></line>}
      {current && <circle className={circleClasses} cx={current[0]} cy={current[1]} r={3}></circle>}
    </>
  );
};
