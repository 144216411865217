import { Flight } from "./models/flight";
import { Hospital } from "./models/hospital";
import { Order } from "./models/order";
import { createNest } from "./models/graph";
import * as _ from 'lodash';
import { PriorityScheduler } from "./models/priority-scheduler";
import { StandardScheduler } from "./models/standard-scheduler";

export class ZipScheduler {
  hospitals: Record<string, Hospital>;
  numZips: number;
  maxPackagesPerZip: number;
  zipSpeedMps: number;
  zipMaxCumulativeRangeM: number;

  private _emergencyQueue: Order[] = [];
  private _standardQueue: Order[] = [];

  // This capacity is only used for emergency orders
  private _launchedFlights: Flight[];
  private _reserveCapacity = 3;
  private _eagerSendCapacity = 8;

  constructor(
    hospitals: Record<string, Hospital>,
    numZips: number,
    maxPackagesPerZip: number,
    zipSpeedMps: number,
    zipMaxCumulativeRangeM: number
  ) {
    this.hospitals = hospitals;
    this.numZips = numZips;
    this.maxPackagesPerZip = maxPackagesPerZip;
    this.zipSpeedMps = zipSpeedMps;
    this.zipMaxCumulativeRangeM = zipMaxCumulativeRangeM;

    // Track which orders haven't been launched yet
    this._emergencyQueue = [];
    this._standardQueue = [];
    this._launchedFlights = [];
  }

  get unfulfilledOrders() {
    return [ ...this._emergencyQueue, ...this._standardQueue ];
  }

  getFlights(time: number) {
    const zipsInAir = this._launchedFlights.filter(flight => flight.returnTime > time);
    return zipsInAir;
  }

  private getAvailableCapacity(time: number) {
    const zipsInAir = this._launchedFlights.filter(flight => flight.returnTime > time).length;
    return this.numZips - zipsInAir;
  }

  /**
   * Add a new order to our queue.
   * Note: called every time a new order arrives.
   * @param {Order} order the order just placed.
   */
  queueOrder(order: Order): void {
    if (order.priority === 'Emergency') {
      this._emergencyQueue.push(order);
    } else {
      this._standardQueue.push(order);
    }
  }

  /**
   * Determines which flights should be launched right now.
   * Each flight has an ordered list of Orders to serve.
   * Note: will be called periodically (approximately once a minute).
   * @param {number} currentTime Seconds since midnight.
   * @returns {Flight[]} List of Flight objects that launch at this time.
   */
  launchFlights(currentTime: number): Flight[] {
    // Nothing to schedule; we can go ahead and just return
    if (this._emergencyQueue.length <= 0 && this._standardQueue.length <= 0 ) {
      return [];
    }

    // Get the number of zips available to schedule
    let availableCapacity = this.getAvailableCapacity(currentTime);

    // All booked right now; nothing can be scheduled
    if (availableCapacity <= 0) {
      return [];
    }

    // Emergency flights
    //
    // These are filled using a simpler single-destination algorithm that only allows one destination, though
    // will group multiple emergency orders that are going to the same place into the same flight, and will also 
    // add any scheduled shipments into the same flight, up to vehicle capacity.
    const priority = new PriorityScheduler({
      zipSpeedMps: this.zipSpeedMps,
      maxPerBin: this.maxPackagesPerZip,
      currentTime,
      availableCapacity
    });
    priority.fillFromQueue(this._emergencyQueue, true);
    priority.fillFromQueue(this._standardQueue, false);
    const priorityFlights = priority.getFlights();

    // Subtract out the flights we just scheduled and also our reserve requirement
    availableCapacity -= priorityFlights.length;
    if (availableCapacity <= this._reserveCapacity + 1) {
      availableCapacity -= this._reserveCapacity;
    }

    // If available capacity is expended, go ahead and return
    if (availableCapacity <= 0) {
      this._launchedFlights = [ ...this._launchedFlights, ...priorityFlights ];
      return priorityFlights;
    }

    // Routine flights
    //
    // This uses a more advanced algorithm (which can be described as a depth-first-search solution to the traveling
    // salesman problem).
    const standard = new StandardScheduler({
      zipSpeedMps: this.zipSpeedMps,
      maxPerBin: this.maxPackagesPerZip,
      maxDistance: this.zipMaxCumulativeRangeM,
      currentTime,
      availableCapacity,
      eagerSendCapacity: this._eagerSendCapacity
    });
    standard.fillFromQueue(this._standardQueue);
    const standardFlights = standard.getReadyFlights();

    const flights = [ ...priorityFlights, ...standardFlights ];
    this._launchedFlights = [ ...this._launchedFlights, ...flights ];
    return flights;
  }

  public getOrderQueue() {
    return [ ...this._emergencyQueue, ...this._standardQueue ];
  }
}
