import * as React from 'react';
import { FlightList } from './flight-list/flight-list';
import { Status } from './status/status';
import { Queue } from './queue/queue';
import './sidebar.css';

export const Sidebar = () => {
  return (
    <div className='sidebar'>
      <Status />
      <FlightList />
      <Queue />
    </div>
  );
}
