import * as React from 'react';
import { SidebarWidget } from '../widget/sidebar-widget';
import { AppContext } from '../../../context';
import './flight-list.css';
import * as _ from 'lodash';

const TITLE = 'Flights';

export const FlightList = () => {
  const { flights } = React.useContext(AppContext);
  const sortedFlights = _.sortBy(_.cloneDeep(flights), flight => flight.launchTime);

  if (sortedFlights.length === 0) {
    return (
      <SidebarWidget title={TITLE}>
        <div className='sidebar-empty-row'>
          No items to display
        </div>
      </SidebarWidget>
    );
  }

  return (
    <SidebarWidget title={TITLE}>
      <div className='sidebar-flight-list'>
        {sortedFlights.map(flight => (
          <div className='sidebar-flight' key={flight.id}>
            <div className='sidebar-flight-number'>
              <strong>Flight #{flight.shortId}</strong>
            </div>
            <div className='sidebar-flight-priority'>
              {flight.priority} &#x2022; {flight.route.orders.length} orders &#x2022; {flight.route.waypoints.length - 2} stops
            </div>
            <div className='sidebar-flight-destination'>
              To: {_.map(flight.route.waypoints.slice(1, flight.route.waypoints.length - 1), wp => wp.location.name).join(', ')}
            </div>
          </div>
        ))}
      </div>
    </SidebarWidget>
  );
}
