import * as React from 'react';
import { AppContext } from '../../context';
import './clock.css';

const formatNumber = (num: number) => {
  if (num > 9) {
    return `${num}`;
  } else {
    return `0${num}`;
  }
}

export const Clock = () => {
  const { time, speedTimesOneThousand, updateSpeedTimesOneThousand, resetSim } = React.useContext(AppContext);

  var hours = Math.floor(time / 3600);
  var minutes = Math.floor(time % 3600 / 60);

  const onMinus = () => {
    updateSpeedTimesOneThousand(Math.min(speedTimesOneThousand / 2, 1));
  }

  const onPlus = () => {
    updateSpeedTimesOneThousand(speedTimesOneThousand * 2);
  }

  return (
    <div className='zip-clock'>
      <div className='zip-clock-time'>
        {formatNumber(hours)}:{formatNumber(minutes)}
      </div>
      <div className='zip-clock-speed'>
        {speedTimesOneThousand}x
      </div>
      <div className='zip-clock-speed-control zip-clock-speed-control-minus' onClick={onMinus}>
        -
      </div>
      <div className='zip-clock-speed-control zip-clock-speed-control-plus' onClick={onPlus}>
        +
      </div>
      <div className='zip-clock-speed-control zip-clock-speed-control-reset' onClick={resetSim}>
        Start over
      </div>
    </div>
  );
};
