import { distance2D } from "./geometry";
import { Hospital } from "./hospital";
import { Location } from "./location";
import * as _ from 'lodash';

export type Edge = { to: Location; weight: number };

export interface HospitalGraph {
  nodes: Location[];
  edges: Map<String, Edge[]>;
}

export interface GraphPath {
  path: Location[];
  totalDistance: number; 
}

const findPaths = (
  graph: HospitalGraph,
  node: Location,
  visited: Set<String>,
  path: Location[],
  totalDistance: number,
  maxDistance: number,
  paths: GraphPath[] = [],
): GraphPath[] => {
  visited.add(node.name);
  path.push(node);

  if (path.length === graph.nodes.length + 1 && path[0].name === node.name) {
    // A full cycle is found, push this path if it doesn't exceed maxDistance
    if (totalDistance <= maxDistance) {
      paths.push({ path: [...path], totalDistance });
    }
  } else {
    const neighbors = graph.edges.get(node.name) || [];
    for (const neighbor of neighbors) {
      if (!visited.has(neighbor.to.name) || neighbor.to.name === path[0].name) {
        findPaths(
          graph,
          neighbor.to,
          new Set(visited),
          [...path],
          totalDistance + neighbor.weight,
          maxDistance,
          paths
        );
      }
    }
  }

  // Remove the current node from the path before backtracking
  visited.delete(node.name);
  path.pop();
  return paths;
}

// Main function: Find the shortest path with constraints
export const findShortestPath = (graph: HospitalGraph, startNode: Location, maxDistance: number): GraphPath | null => {
  const paths = findPaths(graph, startNode, new Set(), [], 0, maxDistance);

  if (paths.length === 0) {
    return null;
  }

  // Find the path with the minimum weight
  return _.reduce(paths, (shortestPath, candidatePath) => {
    if (candidatePath.totalDistance < shortestPath.totalDistance) {
      return candidatePath;
    }
    return shortestPath;
  }, paths[0]);
}

export const createNest = (): Location => {
  return {
    name: 'Nest',
    eastM: 0,
    northM: 0,
  };
}

export const createHospitalGraph = (nest: Location, hospials: Hospital[]): HospitalGraph => {
  const nodes = [nest, ...hospials];
  const edges = _.transform(
    nodes,
    (map, fromNode) => {
      const edges = _.map(nodes, toNode => ({ to: toNode, weight: distance2D(fromNode.eastM, fromNode.northM, toNode.eastM, toNode.northM) }))
      map.set(fromNode.name, _.filter(edges, toNode => fromNode.name !== toNode.to.name));
    },
    new Map<String, Edge[]>()
  );
  return {
    nodes,
    edges
  };
};
