import { Hospital } from "../simulation/models/hospital"
import * as _ from 'lodash';
import { TOTAL_HORIZONTAL_MARGIN, TOTAL_VERTICAL_MARGIN } from "./margins";

const findMin = (hospitals: Hospital[], fn: (hos: Hospital) => number) => {
  return _.reduce(hospitals.slice(1), (min: number, hos: Hospital) => Math.min(min, fn(hos)), fn(hospitals[0]));
}

const findMax = (hospitals: Hospital[], fn: (hos: Hospital) => number) => {
  return _.reduce(hospitals.slice(1), (min: number, hos: Hospital) => Math.max(min, fn(hos)), fn(hospitals[0]));
}

export interface Viewport {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  width: number;
  height: number;
}

export const createViewport = (hospitals: Hospital[]): Viewport => {
  const minX = findMin(hospitals, (hos: Hospital) => Number(hos.eastM));
  const minY = findMin(hospitals, (hos: Hospital) => Number(hos.northM));
  const maxX = findMax(hospitals, (hos: Hospital) => Number(hos.eastM));
  const maxY = findMax(hospitals, (hos: Hospital) => Number(hos.northM));
  const width = Math.abs(maxX - minX);
  const height = Math.abs(maxY - minY);

  return {
    minX,
    minY,
    maxX,
    maxY,
    width,
    height
  }
}

export const getBaselineScale = (viewport: Viewport) => {
  const { innerWidth, innerHeight } = window;
  const xFit = innerWidth / (viewport.width - TOTAL_HORIZONTAL_MARGIN);
  const yFit = innerHeight / (viewport.height - TOTAL_VERTICAL_MARGIN);
  return Math.min(xFit, yFit);
}
