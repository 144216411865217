import * as React from 'react';
import { AppContext } from '../../context';
import { HospitalMarker } from './hospital-marker';
import * as _ from 'lodash';

export const Hospitals = () => {
  const { hospitals } = React.useContext(AppContext);

  return (
    <>
      {_.map(hospitals, hos => <HospitalMarker key={hos.name} hospital={hos} />)}
    </>
  );
};
