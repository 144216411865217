import { v4 } from "uuid";
import { Route } from "./route";
import { Priority } from "./types";
import * as _ from 'lodash';

export class Flight {
  id: string;
  shortId: string;
  launchTime: number;
  route: Route;
  returnTime: number;
  priority: Priority;

  constructor(launchTime: number, route: Route, priority: Priority) {
    this.launchTime = launchTime;
    this.route = route;
    this.returnTime = launchTime + route.flightTime;
    this.priority = priority;
    this.id = v4();
    this.shortId = _.toUpper(this.id.substring(0, 5));
  }

  toString() {
    const ordersStr = this.route.orders
      .map((order) => order.hospital.name)
      .join("->");
    return `<Flight @ ${this.launchTime} to ${ordersStr}>`;
  }
}
