import { NUM_ZIPS, MAX_PACKAGES_PER_ZIP, ZIP_SPEED_MPS, ZIP_MAX_CUMULATIVE_RANGE_M } from "./models/constants";
import { Hospital } from "./models/hospital";
import { Order } from "./models/order";
import { ZipScheduler } from "./scheduler";
import { Flight } from "./models/flight";
import * as _ from 'lodash';

const readFileWeb = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.text();  // or response.json() if you're fetching JSON data
  } catch (e) {
    console.error("There was an error fetching the data: ", e);
    throw e;
  }
}

/**
 * A simulation runner that can playback order CSVs as if the day were
 * progressing.
 */
export class Runner {
  hospitals: Record<string, Hospital>;
  orders: Order[];
  scheduler: ZipScheduler;
  flights: Flight[];
  secondsSinceMidnight: number;

  constructor(hospitals: Record<string, Hospital>, orders: Order[]) {
    this.hospitals = hospitals;
    this.orders = orders;
    this.scheduler = new ZipScheduler(
      hospitals,
      NUM_ZIPS,
      MAX_PACKAGES_PER_ZIP,
      ZIP_SPEED_MPS,
      ZIP_MAX_CUMULATIVE_RANGE_M
    );
    this.flights = [];
    this.secondsSinceMidnight = 0;
  }

  public getHospitals(): Hospital[] {
    return _.values(this.hospitals);
  }

  public getTime(): number {
    return this.secondsSinceMidnight;
  }

  public getFirstOrderTime(): number {
    return this.orders[0].time - 1;
  }

  public stepSimulation(time: number) {
    this.secondsSinceMidnight = time;

    // Find and queue pending orders.
    this._queuePendingOrders(this.secondsSinceMidnight);

    if (this.secondsSinceMidnight % 60 === 0) {
      // Once a minute, poke the flight launcher
      this._updateLaunchFlights(this.secondsSinceMidnight);
    }

    this.flights = this.scheduler.getFlights(this.secondsSinceMidnight);
  }

  public getOrderQueue() {
    return this.scheduler.getOrderQueue();
  }

  /**
   * Grab an order fron the queue and queue it.
   * @param {number} secondsSinceMidnight Seconds since midnight.
   */
  private _queuePendingOrders(secondsSinceMidnight: number) {
    while (this.orders.length && this.orders[0].time === secondsSinceMidnight) {
      // Find any orders that were placed during this second and tell
      // our scheduler about them
      const order: Order = this.orders.shift()!;
      console.log(
        `[${secondsSinceMidnight}] ${order.priority} order received to ${order.hospital.name}`
      );
      this.scheduler.queueOrder(order);
    }
  }

  /**
   * Schedules which flights should launch now.
   * @param {number} secondsSinceMidnight Seconds since midnight.
   */
  private _updateLaunchFlights(secondsSinceMidnight: number) {
    const flights = this.scheduler.launchFlights(secondsSinceMidnight);
    if (flights.length) {
      console.log(`[${secondsSinceMidnight}] Scheduling flights:`);
      for (const flight of flights) {
        console.log(flight);
      }
    }
  }
}

export const createRunner = async () => {
  const hospitalsPath = '/data/hospitals.csv';
  const ordersPath = '/data/orders.csv';
  const hospitals = Hospital.loadFromCsv(await readFileWeb(hospitalsPath));
  const orders = Order.loadFromCsv(
    await readFileWeb(ordersPath),
    hospitals
  );
  return new Runner(hospitals, orders);
}
